<template lang="pug">
    section#banner
        .content
            .left
                h2 
                    | Obrigado por viver essa 
                    br 
                    | experiência inesquecível 
                    br
                    | com a Euro Import.
            .right
                h1 Land Rover Experience
                    img(src="@images/logos/logo-landroverexperience.png", alt="Land Rover Experience")
                .sobre
                    h3 Um encontro de terra, água, fogo e ar.
                    //- time(datetime="2021-10-16") 16 de outubro
                    //- p.rota Curitiba - São Luís do Purunã
                    //- p.reserve Reserve sua agenda.
                    .actions
                        //- router-link(:to="{ name: 'home', hash: '#inscreva-se' }").inscreva-se 
                            span Inscreva-se
                        router-link(:to="{ name: 'home', hash: '#galeria' }") 
                            img(src="@images/icones/icon-camera.png")
                            span Galeria
        picture.glasses
            img(src="@images/objetos/glasses.png")
</template>

<script>
export default {
    name: "section-banner",
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>